
.navbar {
  background-color: var(--dig_primary_color);
  height: 60px;
  position: fixed;
  top: 0;
  width: 100vw;
  margin: auto;
  z-index: 3 !important;

  @media (max-width: 768px) {
    position: fixed;

    &.subOpened{
      height: auto;
    }
    &.subClosed{
      height: 60px;
    }

    z-index: 2;
  }

  .navbar-wrapper {
    @media (max-width: 992px) {
      width: 100%;
      min-height: 100%;
      max-height: 100vh;
      background-color: var(--dig_primary_color);
      margin-top: 0.5rem;
    }
  }

  .navSection {
    margin-left: 17rem;
    margin-right: 15rem;
  }

  .navbarNavWrapper {
    .navbar-nav {
      height: 3.75rem;

      > div.nav-item {
        border-bottom: 2px solid transparent;

        &:hover {
          border-bottom: 2px solid #fff;
        }

        @media (max-width: 768px) {
          border-bottom: 0 !important;
        }
      }

      > .nav-item {

        padding: 0 0.9375rem !important;
        height: 100%;
        max-height: 3.75rem;
        align-items: center;
        display: flex;

        &.logout {
          background: var(--dig_card_header);
          color: var(--dig_neutral5);
          padding: 0 0.625rem !important;
          @media (max-width: 768px) {
            background: none;
            color: white;
          }
        }

        &.dropdown {
          padding: 0;
          position: relative;
          z-index: 999;
          max-height: none;

          .nav-link {
            &.cart-contents {
              &:hover {
                border-color: transparent;
              }
            }

            @media (max-width: 768px) {
              font-size: 1.09375rem;
              &:after {
                right: 10px;
              }
            }
          }

          i {
            margin-left: 0.625rem;
            @media (max-width: 768px) {
              width: 40px;
              height: 20px;
              cursor: pointer;
              text-align: center;
            }
          }

          @media screen and (min-width: 769px) {
            &:hover {
              .sub-menu {
                width: 180px;
                display: block;
                float: none;
                margin-top: 0;
                opacity: 1;
                position: absolute;
                left: 0;
                right: auto;
                top: 58px;
                bottom: auto;
                height: auto;
                transform: none;
              }
            }
          }

          .sub-menu {
            background-color: var(--dig_primary_color);
            color: #fff;
            list-style: none;
            padding-left: 0;
            position: absolute;
            opacity: 0;
            left: -9999px;
            top: 40px;
            width: 180px;

            .dropdown-item {
              font-weight: 700;
              text-transform: uppercase;
              font-size: 0.9375rem;
              padding: 18px 15px 18px 15px;

              &:hover {
                background: var(--dig_card_header);
              }
              @media (max-width: 768px) {
                padding: 12px 15px 12px 15px;
              }
            }

            @media (max-width: 768px) {
              position: relative;
              left: 20px;
              top: 0;
              opacity: 1;
            }

          }

          @media (max-width: 768px) {
            &.opened{
              position: relative;
              opacity: 1;
              left: 0;
              top: 0;
            }

            width: 100%;
            display: block;

          }
        }

        .nav-link {
          color: var(--dig_highlight_2);
          font-weight: 700;
          text-transform: uppercase;
          font-size: 0.9375rem;
          cursor: pointer;
          display: flex;
          border-bottom: none;
          align-items: center;

          &.register {
            color: var(--dig_primary_color);
          }

          @media (max-width: 768px) {
            font-size: 1.09375rem;
            text-align: left;
          }

        }

        @media (max-width: 768px) {
          padding: 0 0.625rem !important;
          width: 100%;
        }
      }

      @media (max-width: 768px) {
        height: 100%;
        width: 100%;
        background: var(--dig_primary_color);
      }
    }
    @media (max-width: 768px) {
      width: 100vw;

    }
  }
  .mobile_header_chat_icon {
    svg g {
      fill: #fff;
    }
  }

  .navbar-toggler i {
    font-size: 1.5625rem;
  }

  .navbar-brand{
    svg {
      fill: #fff;
    }
  }

}

#header-wrap,
#mobile-menu-wrap {
  display: none;
}

.login {
  background: var(--dig_card_header);
  @media (max-width: 768px) {
    background: none;
  }
}

.join {
  background: var(--dig_neutral5);
  color: var(--dig_primary_color);
  @media (max-width: 768px) {
    background: none;
    color: white;
  }
}

