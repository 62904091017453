.loginModal{
  z-index: 999;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 500px;
  width: 100%;
  max-height: 100vh;
  height: auto;
  overflow-y: scroll;

  @media (max-width: 768px) {
    width: 93% !important;
  }
}

.datetimeInput{
  input{
    width: 100%;

  }
}

