.tournament-dashboard{
  .nav-tabs{
    overflow-y: hidden;
  }

}

.caret:after {
  font-family: 'FontAwesome', serif;/* essential to enable caret symbol*/
  content: "\f0d7";/* adjust as needed, taken from font-awesome.css */
  color: grey;
}
.caret-collapsed:after {
  /* symbol for "collapsed" panels */
  content: "\f0da";  /* adjust as needed, taken from font-awesome.css */
}
