
.userBar {
  background-color: transparent;
  position: relative;
  z-index: -1;
  .digPipe {
    height: 57px;
  }
  h3 a,
  h3{
    font-size: 1.375rem;
  }
}

.digActionTrayContentsMobile {
  .actionTrayBalance {
    a {
      font-size: 28px;
    }
  }

  .notifications,
  .profilePic {
    width: 60px;
  }

  .profilePic {
    img {
      border-color: black !important;
    }
  }
}

.userBarMobile{
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  row-gap: 25px;

  > a{
    text-align: center;
    position: relative;
    span{
      left: 65%;

    }
  }
}

.userBarMobileFunds{
  border-top: 1px solid var(--dig_neutral3);
  width: 90%;
  margin: 0 auto;
}

.colorPicker{
  position: absolute;
  top: 3.75rem;
  right: 0.625rem;
}
