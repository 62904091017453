/* https://stackoverflow.com/questions/41439028/webpack-is-renaming-my-scss-classes */

/* Drop-In Gaming Legacy style sheets */
@import "assets/css/dig_um_style_friends_messages.scss";
@import "assets/css/dig_profile_page.scss";
@import "assets/css/style.scss";
@import "assets/css/landing-page.scss";
@import "assets/css/match.scss";
@import "assets/css/card.scss";
@import "assets/css/tournament.scss";
@import "assets/css/user_team_profile.scss";
@import "assets/css/dig_woo.scss";
@import "assets/css/responsive.scss";
@import "assets/css/gg.scss";
@import "assets/css/jquery.bracket.scss";
@import "assets/css/bracket.scss";

html, body {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

body{
  &.chatOpened{
    overflow: auto;

    @media screen and (max-width: 768px) {
      main{

        overflow: auto;
      }

    }
  }
  &.chatClosed{
    overflow: auto;
  }
}

a {
  text-decoration: none;
}

.footer {
  align-self: flex-end;
}

.container {
  max-width: 1280px;
}

.userActionTray{
  background: transparent;
  -webkit-transition: background 1s; /* For Safari 3.0 to 6.0 */
  transition: background 1s; /* For modern browsers */
  &:hover {
    background-color: var(--dig_neutral8);
    animation-duration: 1s;
    animation-iteration-count: infinite;
  }

  top: 60px;
  height: 60px;
  position: fixed;
  width: 100%;
  z-index: 1;
}

#mainPage {

}

.main {
  display: flex;
  flex-direction: column;
  position: fixed;
  overflow-y: scroll;
  overflow-x: hidden;
  width: 100%;

  &::-webkit-scrollbar{

    display: none;
  }

  &.loggedIn {
    top: 120px;
    height: calc(100vh - 120px);
    @media (max-width: 768px){
      margin-top: 60px;
      top: 60px;
    }
  }

  @media (min-width: 1024px) {
    top: 60px;
    overflow-y: scroll;
    overflow-x: hidden;
    height: calc(100vh - 60px);
  }
  @media (max-width: 768px){
    margin-top: 60px;
    overflow-x: hidden;
    position: relative;
  }
}

.main-1 {
  top: 60px;
  position: fixed;
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: calc(100vh - 60px);
}

.digSecondaryHeader {
  height: 60px;
  list-style: none;

  &:hover {
    background-color: var(--dig_neutral7);
    transition: all 1s ease;
  }

}

.hiddenAnchor {
  float: left;
  clear: both
}

.mt60 {
  margin-top: 60px;
}


.notfound {
  height: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center
}

.notfound_404 {
  position: relative;
  height: 240px;

  h1 {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-size: 252px;
    font-weight: 900;
    text-transform: uppercase;
    letter-spacing: -40px;
    margin-left: -20px;
    margin-top: 20px
  }

}


.problemBox {
  text-align: center;
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white
}

.maintenance {
  height: auto;
  /** background: url(assets/img/maintenance-hero.png) no-repeat center; */
  background-size: cover;
  position: relative;
  padding-top: 600px
}

.floatingChatIcon{
  display: flex;
  position: fixed;
  bottom: 1.5em;
  height: 40px;
  width: 40px;
  right: 1.5em;
  z-index: 4;
  background: var(--dig-chat-header);
  justify-content: center;
  align-items: center;
  border: 2px solid var(--dig_neutral2);
  box-shadow: 0 2px 3px 1px #000;

  svg{
    g{
      fill: var(--dig_secondary2);
    }
  }
}

.fontWeightLight {
  font-weight: 300 !important;
}

.fontWeightNormal {
  font-weight: 400 !important;
}

.fontWeightBold {
  font-weight: 700 !important;
}
.digW250{
  width: 250px !important;
  @media (max-width: 768px){
    width: 100% !important;
  }
}

/* 15px */
.fontSize15{
  font-size: 0.9375rem !important;
}
/* 14px */
.fontSize14{
  font-size: 0.875rem !important;
}
/* 12px */
.fontSize12{
  font-size: 0.75rem !important;
}

.min-width-200px{
  min-width: 200px !important;
}
.min-width-150px{
  min-width: 150px !important;
}

.width80{
  width: 80% !important;
}

.width20{
  width: 20% !important;
}
.sortMenu{
  overflow-x: auto;
  gap: 0.625rem;
  white-space: nowrap;
  &::-webkit-scrollbar{
    display: none !important;
  }
}
