footer{
  max-width: 1280px;
  margin: auto;
}
.digFooter {

  background-color: var(--dig_neutral2);
  font-size: .9rem;
  min-width: 100%;

  .contactCol{
    li{
      display: flex;
      align-items: flex-start;
      a{
        display: inline-block;
        padding-top: 0 !important;
      }
      i{
        margin-top: 5px;
      }
    }
  }

  .copyright{
    font-size: 0.70875rem;
    margin-top: 15px;
  }

  h2 {
    margin-left: 30px;
    font-size: 0.875rem;
    margin-bottom: 10px;
  }

  ul {
    li {
      a {
        font-size: 0.7875rem;
        line-height: 18px;
      }

      i {
        font-size: 0.7875rem;
        color: var(--dig_neutral3);
      }
    }
  }

  .digSocial {
    margin-top: 0;

    i {
      font-size: 1.6734375rem;
    }
  }

  .footerSlogan {
    i {
      font-size: 1.81125rem;
      font-weight: 900;
    }

    p {
      padding-bottom: 0;
    }
  }

  //&:hover {
  //
  //  background-color: var(--dig_neutral7);
  //  transition: all 1s ease;
  //
  //}

}

.heroCol {
  margin: auto;

}
