.password-input {
  height: 3.2em !important;
}

.password-disabled {
  opacity: 0.5;
}

.reset-password {
  opacity: 1;
  min-height: 2.3em !important;
}