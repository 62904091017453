.dig-woo-product {
  height: 4rem;
}

.merch-img {
    height: 15em;
    width: 15em;
}

.merch-card {
  border-radius: 5px;
  list-style: none;
  width: 16em;
}
