@import url("https://fonts.googleapis.com/css?family=Share+Tech+Mono|Montserrat:700");

.maintenance-hero {
  background: url("/assets/img/gif/maintenance-hero.gif") no-repeat center center fixed;
  width: 100vw;
    height: 100vh;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  opacity: 0.8;
}

.httpStatusCode {
  font-size: 45vw;
  text-align: center;
  position: fixed;
  width: 100vw;
  z-index: 1;
  color: #ffffff26;
  text-shadow: 0 0 50px rgba(0, 0, 0, 0.07);
  top: 50%;
  transform: translateY(-50%);
  font-family: "Montserrat", monospace;
}

.centeredContainer{
  background-color: rgba(1,1,1,0.9);
  width: 70vw;
  overflow: scroll;
  max-height: 80%;
  position: relative;
  top: 20vh;
  margin: 0 auto;
  padding: 30px 30px 10px;
  box-shadow: 0 0 150px -20px rgba(0, 0, 0, 0.5);
  z-index: 3;
}

.errorTextGeneral {
  font-family: "Share Tech Mono", monospace;
  color: #f5f5f5;
  margin: 0 0 20px;
  font-size: 22px;
  line-height: 1.2;
}

.errorKeys {
  color: #f0c674;
}

.error-values {
  color: #8abeb7;
}


@keyframes slide {
  from {
    right: -100px;
    transform: rotate(360deg);
    opacity: 0;
  }
  to {
    right: 15px;
    transform: rotate(0deg);
    opacity: 1;
  }
}

.error-pre {
  background-color:rgba(18,18,18,0.9);
  max-height: 30%;
  overflow:scroll;
  margin:0 0 1em;
  padding:.5em 1em;
}

.error-pre code,
.error-pre .line-number {
  /* Ukuran line-height antara teks di dalam tag <code> dan <span class="line-number"> harus sama! */
  font:normal normal 12px/14px "Courier New",Courier,Monospace;
  color:black;
  display:block;
}

.error-pre .line-number {
  float:left;
  margin:0 1em 0 -1em;
  border-right:1px solid;
  text-align:right;
}

.error-pre .line-number span {
  display:block;
  padding:0 .5em 0 1em;
}

.error-pre .cl {
  display:block;
  clear:both;
}
