.profile-menu{
  &__item
  {
    &-link{
      font-size: 15px;
      font-weight: 600;
      border-bottom: 1px solid transparent;
      &:hover{
        border-bottom: 1px solid #fff;
      }
    }
  }
}

