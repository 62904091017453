@charset "UTF-8";
.jqgroup.read-write {
  cursor: default;
}
.jqgroup.read-write .match {
  cursor: move;
}
.jqgroup.read-write .match:hover {
  background-color: #ccc;
}
.jqgroup.read-write .rounds .round {
  padding-bottom: 10px;
}
.jqgroup.read-write .rounds .round:first-child {
  background-color: #f99;
}
.jqgroup.read-write .rounds .round:first-child .match {
  background-color: #fcc;
}
.jqgroup.read-write .rounds .round:first-child .match .team {
  border-color: #faa;
}
.jqgroup.read-write .standings table td:first-child {
  padding: 0;
}
.jqgroup.read-write .standings table td:first-child:hover:before {
  color: #333;
  font-size: 1em;
  content: "✎";
  position: relative;
  left: -1em;
}
.jqgroup.read-write .rounds input[type=text].score:focus, .jqgroup.read-write .standings input[type=text]:focus {
  text-decoration: none;
  background-color: #fff;
  border: 1px solid black;
  color: #000;
}
.jqgroup.read-write .rounds input[type=text].score.conflict, .jqgroup.read-write .rounds input[type=text].score.add.conflict, .jqgroup.read-write .standings input[type=text].conflict, .jqgroup.read-write .standings input[type=text].add.conflict {
  background-color: #900;
  color: #fee;
}

.jqgroup {
  font-family: "arial", sans-serif;
  font-size: 16px;
  width: 100%;
  display: inline-block;
  box-sizing: border-box;
}
.jqgroup input {
  box-sizing: border-box;
  height: 22px;
}
.jqgroup .standings {
  white-space: nowrap;
  overflow: hidden;
  margin: 30px 0;
  border: 2px solid var(--dig_neutral6);
}
.jqgroup .standings table {
  border-spacing: 0;
  width: 100%;
}
.jqgroup .standings table tr:nth-child(odd) {
  background-color: var(--dig-chat-odd-row);
}
.jqgroup .standings table tr:nth-child(even) {
  background-color: var(--dig-chat-even-row);
}
.jqgroup .standings table td, .jqgroup .standings table th {
  padding: 9px;
}
.jqgroup .standings table td:nth-child(6) {
  cursor: help;
}
.jqgroup .standings table td:nth-child(6):hover {
  background-color: rgba(255, 255, 255, 0.6);
}
.jqgroup .standings table td {
  border-top: 1px solid var(--dig_neutral6);
}
.jqgroup .standings table td:first-child {
  position: relative;
  padding-left: 10px;
}
.jqgroup .standings table td:not(:first-child) {
  text-align: center;
}
.jqgroup .standings table th {
  text-align: center;
}
.jqgroup .standings table th :first-child {
  text-align: left;
}
.jqgroup .standings table .drop {
  cursor: pointer;
  background-color: #fee;
}
.jqgroup .standings table .drop:hover {
  background-color: #f00;
}
.jqgroup .rounds {
  float: left;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}
@media (max-width: 768px) {
  .jqgroup .rounds {
    grid-template-columns: repeat(1, 1fr);
  }
}
.jqgroup .roundsHeader {
  font-size: 20px;
  cursor: pointer;
}
.jqgroup .roundsHeader:hover {
  text-decoration: underline;
}
.jqgroup .unassigned {
  background-color: #aaa;
}
.jqgroup .participant {
  background-color: #eee;
}
.jqgroup .match {
  min-width: 50px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 5px 0;
  align-items: center;
  position: relative;
}
.jqgroup .match:nth-child(n+3) {
  border-top: 1px solid var(--dig_neutral6);
}
.jqgroup .match:nth-child(n+3) div.team.highlight {
  border-color: #0b0;
}
.jqgroup .match div.team {
  width: 50%;
  text-align: left;
  display: flex;
  align-items: center;
}
.jqgroup .match div.team:last-of-type {
  text-align: right;
}
.jqgroup .match:hover:before {
  content: "View Match";
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  background: black;
  opacity: 0.7;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  font-weight: bold;
}
.jqgroup .match:hover .view-match {
  width: 100%;
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
}
.jqgroup .match .score, .jqgroup .match div.label {
  box-sizing: border-box;
  padding: 3px;
  float: left;
}
.jqgroup .match .score {
  font-size: 14px;
  width: 20%;
  border: none;
  text-align: center;
  background-color: rgba(255, 255, 255, 0.3);
}
.jqgroup .match .score.win {
  color: #fff;
}
.jqgroup .match .score.lose {
  color: #900;
}
.jqgroup .match div.label {
  display: inline-block;
  overflow: hidden;
  width: 80%;
}
.jqgroup .round {
  width: 100%;
  float: left;
  margin-top: 10px;
  clear: both;
  background-color: var(--dig_neutral8);
  padding: 10px;
}
.jqgroup .round.dropable {
  background-color: #8c8;
}
.jqgroup .round.dropable .match {
  opacity: 0.7;
}
.jqgroup .round.dropable.over {
  background-color: #8f8;
}
.jqgroup [data-roundid="0"] {
  display: none;
}
.jqgroup header {
  padding: 0 2px 10px;
  font-size: 16px;
  display: block;
  text-align: center;
  text-transform: uppercase;
}
.jqgroup .highlight {
  background-color: #3c0;
}

