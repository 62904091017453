
.dropdown-toggle{
  border-radius: 0;
  padding: 0.3125rem 0.625rem;
  font-size: 1rem;
}

.dropdown-menu{
  width: 100%;
  display: block;
  max-height: 345.047px;
  overflow: hidden;
  min-height: 73px;
  position: absolute;
  transform: translate3d(0px, 39px, 0px); // -152px or 39px
  top: 0;
  left: 0;
  will-change: transform;
  border-radius: 0;
  padding: 0;
  z-index: 1;
  ul{
    list-style: none;
    padding: 0;
    li{
        font-size: 15px;
        padding: 4px 6px;
        &:hover{
          background-color: #f8f9fa;
          cursor: pointer;
        }
    }
  }
  @media screen and (max-width: 767px){

      transform: none;
      position: relative;
  }
}
