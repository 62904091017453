.leaderBoardTabs {
  .tabs {
    ul {
      display: flex;
      padding-left: 0;

      li {
        font-size: 18px;
        font-weight: 600;
        cursor: pointer;
        list-style: none;
        padding: 10px 15px;

        &.active,
        &:hover {
          background-color: gray;
        }
      }
    }
  }
}

.leaderBoardWrapper {
  width: 100%;
  height: 100%;

  .leaderBoardItem {
    padding: 1.5625rem;

    .leaderBoardHeader {
      background-color: var(--dig-chat-header);

      th {
        padding: 0.9375rem 0;
        text-align: center;
      }
    }

    .leaderBoardRow {

      td {
        text-align: center;
      }

      &:nth-child(odd) {
        background-color: var(--dig-chat-odd-row);

      }

      &:nth-child(even) {
        background-color: var(--dig-chat-even-row);

      }

      .profile {
        text-align: left;

      }

      img {
        width: auto;
        height: 1.875rem;
        margin: 0.9375rem 0;
        border-radius: 0.3125rem;
      }


    }

  }

}

.leftArrow {
  left: 15px;

  &:before {
    font-size: 50px;
  }
}

.rightArrow {
  right: 45px;

  &:before {
    font-size: 50px;
  }
}

.rightArrow,
.leftArrow {
  z-index: 1;
  @media screen and (max-width: 768px) {
    z-index: 2;
  }
}

.stretchedLink{
  &::after{
    z-index: 2;

  }

}

.digSlider {
  max-width: 1280px;
  margin: auto;
}

.hero {
  position: relative;
  width: 100%;
  background-color: black;


  h1 {
    font-size: 3.5rem;
  }

  p {
    font-size: 2rem;
  }

  picture{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    img{
      width: 100%;
      height: 100%;
      object-fit: contain;

    }
  }

  @media screen and (max-width: 768px) {
    background-size: contain !important;
    background-repeat: no-repeat !important;
  }

  .hero-bg {
    min-height: 37.5rem;
    @media screen and (max-width: 768px) {
      min-height: 35rem;
    }
    @media screen and (max-width: 590px) {
      min-height: 30rem;
    }
    @media screen and (max-width: 425px) {
      min-height: 28rem;
    }
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;

    .intro {
      //background: rgba(0, 0, 0, 0.22);
      z-index: 1;

      &:after {
        content: "";
        //background-color: rgba(0, 0, 0, 0.5);
        height: 55%;
        position: absolute;
        width: 100%;
        display: block;
        bottom: 0;
        z-index: -1;
        left: 0;
      }

      h1, p {
        font-style: italic;
      }

      h1 {
        font-size: 65px;
        font-weight: 900;
      }

      p {
        font-size: 31px;
        font-weight: 600;
      }

      .btn-lg {
        padding: 0.625rem 2.25rem;
        font-size: 1.25rem;
      }

      @media screen and (max-width: 768px) {
        h1 {
          font-size: 25px;
        }
        p {
          font-size: 16px;
        }
      }
    }
  }
}

.heroCol {
  margin: auto;
  max-width: 1280px;
}

.bg {
  background-color: var(--dig_neutral1);
}

#dig-landing-page-get-started {
  h2 {
    font-weight: 700;
    font-size: 3rem;
  }
}

.discover {
  &.match,
  &.brackets,
  &.tournament {
    background-size: cover;
  }
}

.feature-box {
  .container {
    background-color: var(--dig_neutral1);
  }
}

#hero {
  max-width: 1280px;
  margin: auto;
}

.digLandingPageGetStarted {
  background: #323233 url("https://www.dropingaming.com/wp-content/themes/dropingaming/assets/img/new-background-flourish.png") no-repeat top left;
  background-size: 50%;

  h2 {
    font-size: 28px;
  }

  .digSuper {
    font-size: 56px;
  }

  .digGetStartedElement {
    .info {
      a {
        font-size: 18px !important;
      }
    }
  }

  .getStarted {
    background: none;
    width: 135px;
  }

}

.digLandingPageContent {
  ul {
    font-size: 1.5em;
    font-weight: 700;
    list-style: none;
    padding: 0;
    width: 35vw;

    li:first-child {
      text-align: center;
      font-size: 1.875rem;
      margin-bottom: 20px;
    }

    .find {
      text-align: center;
      font-weight: 400;
    }

    @media screen and (max-width: 768px) {
      width: 95vw;
    }
  }
}

.digUserActionGroup {
  input[type="submit"] {
    font-size: 16px !important;
  }
}

.middle {
  width: 100%;
}

.playingTypePicsMobile {
  svg {
    fill: var(--dig_secondary2);
  }
}
