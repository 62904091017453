button.btn.btn-sort {
  background-color: transparent !important;
  border: 3px solid var(--dig_neutral2);
  margin: 1.5rem;
  color: white;
  font-size: 1rem;
  text-transform: uppercase;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: 768px){
    font-size: 0.75rem;
    margin: 1rem 0 0;
  }
}

.caret {
  cursor: pointer;
  user-select: none; /* Prevent text selection */
  padding: 0 .5rem;
}


