.dig-wallet-options {
    margin-bottom: 15px;
    display: flex;

    a {
        padding: 10px;
        background: var(--dig_neutral2);
        text-transform: uppercase;
        display: block;
        width: 150px;
        text-align: center;
        &.selected {
            background: var(--dig_secondary4);
            font-weight: 600;
        }
    }
}
.form-row {
    display: flex;
    flex-wrap: wrap;
    margin-left: 0 !important;
    margin-right: 0 !important;
}

#billing_first_name_field {
    width: 50%;
    float: left;
    padding-right: 15px !important;
}

#billing_last_name_field {
}
#billing_country_field {
    select {
        width: 100%;
        padding: 5px 10px;
    }
}

.preset-amount {
    max-width: 70px;
    width: 70px;
    height: 30px;
    &.active {
        background-color: var(--dig_highlight_1) !important;
    }
}

.dig-add-funds-amount {
    .input-group-text {
        background: var(--dig_highlight_1);
        border: 0px;
        color: var(--dig_highlight_2);
        font-weight: 700;
    }
}

.dig-cost-panel {
    label {
        font-size: 0.75em;
        font-weight: bold;
        color: var(--dig_highlight_1);
        margin: 0;
    }
    p {
        margin: -1px 0px 0px 0px;
        font-size: 1.17em;
        font-weight: 500;
    }
}
.dig-wallet-payment {
    img {
        height: 30px;
        display: inline !important;
        padding-left: 10px;
    }
}
#dig-deposit-options-check-label-paypal,
#dig-deposit-options-check-label-cc {
    input[type="radio"] {
        width: 24px;
        height: 24px;
    }
}
#dig-cc-number iframe,
#dig-cc-exp iframe,
#digCvv iframe {
    height: 35px !important;
    background-color: #fff;
    border: 0;
}
#dig-deposit-details,
#dig-withdrawal-details {
    img {
        max-width: 100px;
    }
}
#terms_and_conditions {
    width: 24px;
    height: 24px;
    margin-right: 1rem;
}
#dig-add-funds-button,
#dig-withdraw-funds-button {
    font-size: 1em;
    line-height: 1em;
    padding: 13px 20px 13px 20px;
    margin-top: 10px;
    margin-left: 0px;
    height: auto;
    width: auto;
    border: 1px solid var(--dig_primary_color);
    border-radius: 0;
    opacity: 1;
    background: var(--dig_primary_color);
    color: var(--dig_highlight_2);
}

.dig-withdraw {
    width: 50vw !important;
}

.equifax-img {
    height: 85px;
}

.modal-dialog-centered {
    display: flex;
    align-items: center;
    min-height: calc(100% - (1.75rem * 2));
    justify-content: center;
}

@media only screen and (max-width: 768px) {
    .equifax-img {
        height: 70px;
    }

    .wallet-modal {
        max-width: 40em !important;
    }

    .dig-add-funds-amount {
        width: 7em;
    }
}


@media only screen and (max-width: 480px) {
    .equifax-img {
        height: 75px;
    }

    .wallet-modal {
        max-width: 22em !important;
    }

    .dig-add-funds-amount {
        width: 6em;
        .input-group-text {
            font-size: 0.8em;
        }
    }
}


