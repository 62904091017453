@media screen and (max-width: 768px) {
    .chat-wrapper {
        display: none;
    }
}


.digChatBox{
    overflow: hidden;
    min-height: 400px;
    width: 350px;
    max-width: 1280px;
    position: fixed;
    right: 0;
    z-index: 4;
    background-color: var(--dig_object_body);
    border: 1px solid var(--dig_highlight_3);

    &.loggedIn{
        //max-height: calc(100% - 120px);
    }
    @media screen and (max-width: 768px) {
        position: absolute;
        z-index: 2;
        width: 100%;

        .listGroup{
            height: 100px;
            overflow-x: hidden;
            display: flex;
            flex-direction: column-reverse;
            scroll-snap-type: y proximity;

            &:last-child {
                scroll-snap-align: end;
            }

        }

    }

}



.chatHeader{
    background-color: var(--dig-chat-header);
    h3{
        font-size: 21px;
        font-weight: 400;
    }
    .chatIcons{
        svg{
            fill: var(--dig_neutral3) !important;
            width: 24px;
            height: 24px;
        }
    }
}

.chatInputBox{
    background-color: var(--dig_neutral8);
    height: 100px;
    border-top: 1px solid var(--dig_highlight_6);
    button{
        font-size: 0.875rem;
        color: var(--dig_highlight_2);
        font-weight: 400;
        padding: 10px 20px;
        max-width: 300px;
        height: 50px !important;
        border: 0;
    }
}

.chatlistIitem{
    &:nth-child(even){
        background-color: var(--dig-chat-even-row);
    }
    &:nth-child(odd){
        background-color: var(--dig-chat-odd-row);
    }
}

.chat_bubble{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    svg{
        width: 20px;
    }
}

.submitChat{
    width: 48px !important;
}

.chatBoxListItem, .bot__output {
    word-wrap: normal;
    white-space: normal;
    display: block;
    height: auto;
    margin: 0px;
    line-height: 1.4;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    font-size: 18px;
    padding: 8px;
    border-top: 2px solid rgba(62, 62, 62, .8);
    overflow-y: -moz-scrollbars-none;
}

.chatBoxListItem:nth-child(odd){
    background-color: var(--dig-chat-odd-row);
}

.chatBoxListItem:nth-child(even){
    background-color: var(--dig-chat-even-row);
}

.digChatBoxBodyBox {
    height: calc(100% - 161px);
    width: 100%;
    padding: 0;
    bottom: 0px;
    background-color: rgba(42, 42, 42, 1);
    overflow-x: -moz-scrollbars-none;
    overflow-y: scroll;
}

.dig-comp-box-body-box {
    width: 100%;
    padding: 0px;
    background-color: rgba(42, 42, 42, 1);
    overflow-x: -moz-scrollbars-none;
    overflow-y: scroll;
}

.digChatCompName {
    text-align: left;
    float: left;
    width: 80%;
}

.digChatCompDetails {
    float: left;
    color: var(--dig_neutral3);
    width: 80%;
    padding-top: 20px;
}
