.pagination{
  > button{
    color: #fff;
    height: auto !important;
    min-width: auto !important;
    min-height: auto !important;

    > div{
      &:before{
        content: " " !important;
        margin: 0;
      }
    }
  }
}
