$boxHeight: 22px;
$boxPadding: 3px;

.jqgroup.read-write {
  cursor: default;

  .match {
    cursor: move;

    &:hover {
      background-color: #ccc;
    }
  }

  .rounds {
    .round {
      padding-bottom: 10px;

      &:first-child {
        background-color: #f99;

        .match {
          background-color: #fcc;

          .team {
            border-color: #faa
          }
        }
      }
    }
  }

  .standings {
    table{
      td:first-child{
        padding: 0;
        &:hover:before{
          color: #333;
          font-size: 1em;
          content: '\0270E';
          position: relative;
          left: -1em;
        }
      }
    }
  }

  .rounds input[type=text].score, .standings input[type=text]{
    &:focus{
      text-decoration: none;
      background-color: #fff;
      border: 1px solid black;
      color: #000;
    }
    &.conflict, &.add.conflict{
      background-color: #900;
      color: #fee;
    }
  }
}

.jqgroup{
  font-family: "arial",sans-serif;
  font-size: 16px;
  width: 100%;
  display: inline-block;
  box-sizing: border-box;

  input{
    box-sizing: border-box;
    height: $boxHeight;
  }
  .standings{
    white-space: nowrap;
    overflow: hidden;
    margin: 30px 0;
    border: 2px solid var(--dig_neutral6);

    table{
      border-spacing: 0;
      width: 100%;
      tr{
        &:nth-child(odd){
          background-color: var(--dig-chat-odd-row);
        }
        &:nth-child(even){
          background-color: var(--dig-chat-even-row);
        }

      }
      td, th{
        padding: 9px;
      }
      td:nth-child(6){
        cursor: help;
        &:hover{
          background-color: rgba(255,255,255,0.6);
        }
      }

      td{
        border-top: 1px solid var(--dig_neutral6);
      }

      td:first-child{
        position: relative;
        padding-left: 10px;
      }

      td:not(:first-child){
        text-align: center;
      }

      th{
        text-align: center;
        :first-child{
          text-align: left;
        }
      }

      .drop{
        cursor: pointer;
        background-color: #fee;
        &:hover{
          background-color: #f00;
        }
      }

    }
  }

  .rounds{
    float: left;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2,1fr);
    gap: 20px;
    @media (max-width: 768px) {
      grid-template-columns: repeat(1,1fr);
    }
  }

  .roundsHeader{
    font-size: 20px;
    cursor: pointer;
    &:hover{
      text-decoration: underline;
    }
  }

  .unassigned{
    background-color: #aaa;
  }

  .participant{
    background-color: #eee;
  }

  .match{
    &:nth-child(n+3){
      border-top: 1px solid var(--dig_neutral6);
      div.team{
        &.highlight{
          border-color: #0b0;
        }
      }
    }
    min-width: 50px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 5px 0;
    align-items: center;
    position: relative;
    div.team{
      width: 50%;
      text-align: left;
      display: flex;
      align-items: center;

      &:last-of-type {
        text-align: right;
      }

    }

    &:hover{
      &:before{
        content: "View Match";
        position: absolute;
        width: 100%;
        top: 0;
        left: 0;
        background: black;
        opacity: .7;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        text-transform: uppercase;
        font-weight: bold;
      }

      .view-match{
        width: 100%;
        position: absolute;
        height: 100%;
        top: 0;
        left: 0;
      }
    }



    .score, div.label{
      box-sizing: border-box;
      padding: $boxPadding;
      float: left;
    }
    .score{
      font-size: 14px;
      width: 20%;
      border: none;
      text-align: center;
      background-color: rgba(255, 255, 255, 0.3);
      &.win{
        color: #fff;
      }
      &.lose{
        color: #900;
      }
    }

    div.label{
      display: inline-block;
      overflow: hidden;
      width: 80%;
    }
  }
  .round{
    width: 100%;
    float: left;
    margin-top: 10px;
    clear: both;
    background-color: var(--dig_neutral8);
    padding: 10px;

    &.dropable{
      background-color: #8c8;
      .match{
        opacity: .7;
      }
      &.over{
        background-color: #8f8;
      }
    }
  }
  [data-roundid="0"]{
    display: none;
  }
  header{
    padding: 0 2px 10px;
    font-size: 16px;
    display: block;
    text-align: center;
    text-transform: uppercase;
  }
  .highlight{
    background-color: #3c0;
  }
}
