.ui-selected {
    background: var(--dig_primary_color);
}

.ui-selected div {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
}

.dig-buy-selector-custom{
    svg{
        display: flex;
        max-width: 200px;
        width: 100%;
        fill: #fff;
        height: 100%;
    }
}

